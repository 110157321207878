import api from '../library/axios';
import config from '../config';

export default {
  topupHistories({ id, query = {} }) {
    return api({
      url: `/api/topup/${id}`,
      params: query,
    });
  },
  paymentMethodList() {
    return api({
      url: '/api/topup/payment-list',
    });
  },
  topupInquiryLastRequest({ id }) {
    return api({
      url: `/api/topup/${id}/inquiry`,
    });
  },
  createRequest({ id, data }) {
    return api({
      method: 'post',
      url: `/api/topup/${id}`,
      data,
    });
  },
  cancelRequest({ id, topup_id }) {
    return api({
      method: 'delete',
      url: `/api/topup/${id}/${topup_id}`,
    });
  },
  detailRequest({ id, topup_id }) {
    return api({
      url: `/api/topup/${id}/${topup_id}`,
    });
  },
  depositHistories({
    workspace_id,
    company_id,
    token,
    query,
  }) {
    return api({
      url: `/api/workspaces/${workspace_id}/${company_id}/balance/history`,
      params: query,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  calculateTopup({
    workspace_id,
    data,
  }) {
    return api({
      method: 'post',
      url: `/api/topup/${workspace_id}/calculate`,
      data,
    });
  },
  downloadInvoices({
    workspace_id,
    id,
  }) {
    return `${config.api.baseUrl}/api/topup/download-invoice/${workspace_id}/${id}`;
  },
};
